<template>
  <div class="py-6 px-4">
    <div class="d-flex justify-space-between align-center">
      <v-btn :to="{ name: 'Login' }" text class="text-none primary--text">
        <span class="material-symbols-rounded icon"> arrow_back </span>
        <span class="ml-2 p">Volver al Login</span>
      </v-btn>
      <v-img class="global-logoimg" src="@/assets/logo-mclick-min.png">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <v-container>
      <v-row>
        <v-col class="text-center" cols="12">
          <span class="h3 semiBold">Registro de usuario</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card elevation="0" outlined class="pa-6">
            <v-form ref="form">
              <v-card-text>
                <v-row>
                  <v-col>
                    <span class="h6 primary--text semiBold"
                      >Datos particulares</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Nombre*" />
                    <TextField :fields="Nombre" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Primer Apellido*" />
                    <TextField :fields="Apellido" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Segundo apellido*" />
                    <TextField :fields="SegundoApelido" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Teléfono*" />
                    <v-text-field
                      v-model="phone.text"
                      :label="phone.label"
                      :rules="phone.rules"
                      outlined
                      dense
                      :style="cssVars2"
                      v-mask="phoneMask"
                      return-masked-value
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Canal*" />
                    <v-select
                      v-model="Text_Canal.selected"
                      :items="Text_Canal.answers"
                      :rules="Text_Canal.rules"
                      item-text="label"
                      item-value="value"
                      atta
                      outlined
                      dense
                      :style="cssVars2"
                      no-data-text="No hay información"
                      append-icon="mdi-chevron-down"
                    ></v-select>
                    <!-- <Select
                      :fields="Text_Canal"
                      :loading="loadingChannels"
                    /> -->
                  </v-col>
                  <v-col cols="12" md="6">
                    <!-- <LabelComponent text="Sucursal" />
                    <Select
                      :fields="Text_Sucursal"
                      @change="getSucursal()"
                      color="#0077c8"
                    /> -->
                    <LabelComponent text="Correo*" />
                    <TextField :fields="Correo" />
                  </v-col>
                  <!-- <v-col cols="12">
                    <LabelComponent text="Correo*" />
                    <TextField :fields="Correo" />
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col>
                    <span class="h6 primary--text semiBold">Tu usuario</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <LabelComponent text="RUT*" />
                    <Input
                      size="long"
                      color="#0077c8"
                      :model="rut"
                      name="rut"
                      :hide="false"
                      readonly="false"
                      :rules="[rules.required, rules.isValid, rules.rutFormat]"
                      @input="rut = $event"
                      max_length="12"
                      :style="cssVars2"
                      :rut="true"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Contraseña*" />
                    <TextField :fields="Password" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <LabelComponent text="Confirmar contraseña*" />
                    <TextField :fields="ConfirmPassword" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <v-btn
                      class="mx-1 text-none"
                      color="primary"
                      rounded
                      width="160"
                      @click.native="PostNewUser()"
                      :loading="loadingstatus"
                      >Registrar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Modal
      :close="closeDialog"
      :open="usermodal"
      :name="Nombre.text"
      :email="Correo.text"
      :rut="rut"
      :ActionButton="ActionButton"
    />
    <SnackBar :snackbar="SnackBar" :msg="errormsg" />
  </div>
</template>

<script>
// import stepper fro m "@/components/Stepper/Stepper";
import TextField from "@/components/Inputs/TextField";
/* import Select from "@/components/Inputs/SelectLabel"; */
import Input from "@/components/Inputs/Input.vue";
import Modal from "@/components/Modal/Modal-New-User";
import { Register } from "../.././Mixins/RegisterMixin";
import axios from "axios";
import SnackBar from "@/components/Snackbar/Snack-error";
import LabelComponent from "@/components/Inputs/Label.vue";
import {
  validarContrasena,
  validarRut,
  validarRutFormat,
} from "@/store/resources/validate.js";

import { phoneMask } from "@/helpers/mask.js";

export default {
  mixins: [Register],
  components: {
    // stepper,
    TextField,
    /*  Select, */
    LabelComponent,
    Input,
    Modal,
    SnackBar,
  },
  data() {
    return {
      SnackBar: false,
      errormsg: "",
      rules: {
        required: (value) => !!value || "El RUT es requerido.",
        isValid: (value) => validarRut(value),
        rutFormat: (value) => validarRutFormat(value),
      },
      step: 1,
      rut: "",
      e1: 1,
      disabled: true,
      items: [
        { id: 1, label: "Persona", icon: "bi bi-person" },
        { id: 2, label: "Autorizar", icon: "bi bi-shield-lock" },
      ],

      Nombre: {
        label: "",
        text: "",
        rules: [(v) => !!v || "Nombre es requerido"],
      },

      Apellido: {
        label: "",
        text: "",
        rules: [(v) => !!v || "Primer Apellido es requerido"],
      },

      SegundoApelido: {
        label: "",
        text: "",
        rules: [(v) => !!v || "Segundo Apellido es requerido"],
      },

      phone: {
        label: "",
        text: "",
        rules: [
          (v) => !!v || "Número de Celular es requerido",
          (v) => v.length == 9 || "Debe tener 9 números",
        ],
      },

      Correo: {
        label: "",
        text: "",
        rules: [
          (v) => !!v || "Correo eletrónico es requerido",
          (v) => /.+@.+\..+/.test(v) || "El Correo debe ser válido",
        ],
      },

      Text_Canal: {
        label: "",
        answers: [],
        selected: "",

        id: [],
        rules: [(v) => !!v || "Canal es requerido"],
      },

      Text_Sucursal: {
        label: "",
        answers: [],
        selected: "",
        disabled: true,
        rules: [(v) => !!v || "Sucursal es requerido"],
      },
      Password: {
        label: "",
        text: "",
        type: "password",
        rules: [
          (v) => !!v || "Contraseña es requerido.",
          (v) => validarContrasena(v),
        ],
      },
      ConfirmPassword: {
        label: "",
        text: "",
        type: "password",

        rules: [
          (v) => !!v || "Contraseña es requerido.",
          (v) => v === this.Password.text || "las contraseñas no coinciden",
        ],
      },
    };
  },
  methods: {
    closeDialog() {
      this.$store.commit("usermodal", false);
      this.$router.push({ name: "Login" });
    },
    ActionButton() {
      this.$store.dispatch("CloseUserModal").then(this.$router.go(-1));
    },
    PostNewUser() {
      const Form = {
        Name: this.Nombre.text,
        Nickname: this.Apellido.text,
        secondNickname: this.SegundoApelido.text,
        phone: this.phone.text,
        address: this.Correo.text,
        canal: this.Text_Canal.selected,
        /* cursal: this.Text_Sucursal.selected, */
        Rut: this.rut.replace(/[^A-Z0-9]/gi, ""),
        password: this.Password.text,
      };
      if (this.$refs.form.validate()) {
        this.$store.commit("loadingstatus", true);
        axios({
          url: "solicitudes",
          method: "POST",
          data: {
            nombre: Form.Name,
            primer_apellido: Form.Nickname,
            segundo_apellido: Form.secondNickname,
            celular: Form.phone,
            correo_electronico: Form.address,
            canal: Form.canal,
            /* sucursal: 24, */
            usuario: Form.Rut,
            clave: Form.password,
            convenio: this.$store.getters.url,
            origen_solicitud: "formulario inicio",
            modulo: "venta",
            pais: "CL",
            tipo_documento: "RUT",
          },
        })
          .then(() => {
            this.e1 = 2;
            this.$store.commit("loadingstatus", false);
            this.$store.commit("usermodal", true);
          })
          .catch((err) => {
            this.errormsg = err.response.data.data.lista_mensaje[0].mensaje;

            this.SnackBar = true;
            setTimeout(() => (this.SnackBar = false), 2000);
            this.$store.commit("loadingstatus", false);
          });
      }
    },
  },
  computed: {
    phoneMask: () => phoneMask,

    cssVars2() {
      return {
        "--bg-color": this.phone.text === "" ? "#BEBEBE" : "#A9A9A9",
        "--bg-color-text": this.phone.text === "" ? "#A0A0A0" : "#A0A0A0",
        "--detail-color": this.detail_color,
      };
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
@import "../../assets/CSS/SelectField.css";
@import "../../assets/CSS/TextField.css";
:deep().v-text-field fieldset,
.v-text-field.v-input--has-state fieldset {
  border: 1px solid var(--bg-color);
}
.v-input >>> label {
  color: var(--bg-color-text) !important;
}
.v-text-field >>> input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  font-size: 12px !important;
  color: #050c42 !important;
  background-color: transparent !important;
  border: 5px;
}
.icon {
  font-size: 15px;
}
.v-btn__content {
  letter-spacing: 0.1px !important;
}
.primary--text {
  color: #0077c8 !important;
}
.h3 {
  color: #223a65 !important;
}
</style>
